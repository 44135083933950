import React, { useEffect, useState } from "react";
import CircularProgress from '@mui/material/CircularProgress';
import actions from "../../actions/actions";
import PostEdit from "./post-edit";
import Snackbar from '@mui/material/Snackbar';


const PostReview = (props) => {

    const [nextPage, setNextPage] = useState(1)
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [selectedItem, setSelectedItem] = useState(null)
    const [openEdit, setOpenEdit] = useState(false)
    const [showSnack, setShowSnack] = useState(false)
    const [snackMessage, setSnackMessage] = useState('')

    useEffect(async () => {
        await loadPages()
    }, [])

    async function loadPages() {
        setLoading(true)
        let response = await actions.getPostsPaginated(nextPage)
        if (response.data) {
            setNextPage(response.data.nextPage)
            setData(data => [...data, ...response.data.docs]);
        }
        setLoading(false)
    }

    function onPostclicked(item) {
        setSelectedItem(item)
        setOpenEdit(true)
    }

    async function onNewItemSave(item) {
        try {
            let response = await actions.updatePost(item)
            if (response.data) {
                setSnackMessage('Updated successfully')
            } else {
                setSnackMessage('Failed to update. Do it again.')
            }
        } catch (e) {
            setSnackMessage('Server error. Do it again.')
        }
        setShowSnack(true)
    }

    function getImage(item) {
        try {
            return item.metas.image
        } catch (e) {
            return null
        }
    }

    function openLink(e, link) {
        e.stopPropagation()
        window.open(link, '_blank');
    }
    return <>
        <div className="posts-review-main">

            {
                (data !== null && data.length > 0) ? data.map((item) => {
                    return <div key={item.id} className="post-item" onClick={() => onPostclicked(item)}>

                        {
                            (item.metas !== null) ? <img className="post-item-image" src={getImage(item)}></img> : <></>
                        }

                        <div className="post-item-info">
                            <div className="post-item-title">
                                {item.title}
                            </div>
                            <div className="post-item-site">
                                {new URL(item.link).host}
                            </div>
                            <div className="post-item-button" onClick={(e) => openLink(e, item.link)}>
                                Open Link
                            </div>
                        </div>

                    </div>
                }) : <></>
            }

            {
                (nextPage !== null && !loading) ?
                    <div className="posts-loadmore" onClick={() => loadPages()}>
                        Load More
                    </div> : <></>
            }
            {
                loading ? <div className="posts-loader">
                    <CircularProgress />
                </div> : <></>
            }
            {
                selectedItem !== null ? <PostEdit item={selectedItem} open={openEdit} onDialogClose={() => { setOpenEdit(false) }}
                    onSaveClick={(e) => { onNewItemSave(e) }} /> : <></>
            }

            <Snackbar
                open={showSnack}
                autoHideDuration={1500}
                onClose={() => { setShowSnack(false) }}
                message={snackMessage}
            />
        </div>
    </>
}

export default PostReview