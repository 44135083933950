import React, { useEffect, useState } from "react";
import './home.css'
import * as firebase from './../../services/firebase'
import { useNavigate } from "react-router-dom";
import AppBar from "../../widgets/appbar/appbar";
import Dialog from '@mui/material/Dialog';
import CircularProgress from '@mui/material/CircularProgress';
import actions from './../../actions/actions'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Posts from "./posts";
import PostReview from "./post-review";
import Twitter from "./twitter";
import TweetReview from "./tweet-review";
import { subscribe } from "../../actions/eventbus";


const Home = (props) => {
    let navigate = useNavigate();
    const [tabValue, setTabValue] = React.useState(0);
    const [posts, setPosts] = React.useState([])
    const [showLoading, setShowLoading] = useState(false)

    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    };

    useEffect(async () => {
        firebase.auth.onAuthStateChanged((user) => {
            if (!user) {
                goToLogin()
            }
        })
        await getAndSaveToken()
        subscribeToEvents()
    })


    function subscribeToEvents() {
        subscribe("logout", async (message) => {
            await signOut()
        })
    }

    async function getAndSaveToken() {
        try {
            let token = await firebase.auth.currentUser.getIdToken(true)
            localStorage.setItem('token', token)
        } catch (e) {

        }
    }

    function goToLogin() {
        navigate("/", { replace: true });
    }

    function onPostAddClick(item) {
        const updated = [...posts, item]
        setPosts(updated)
    }

    function clearList() {
        setPosts([])
    }

    async function onPushPosts() {
        try {
            setShowLoading(true)
            let token = await firebase.getUser().getIdToken()
            let uid = await firebase.getUser().uid
            await actions.postPosts(posts, token, uid)

            clearList()
        } catch (e) {
            console.log(e)
        }
        setShowLoading(false)
    }

    async function signOut() {
        try {
            setShowLoading(true)
            await firebase.performSignOut(firebase.auth)
            goToLogin()
        } catch (e) {
            console.log(e)
        }
        setShowLoading(false)
    }

    function getContentLayout(index) {
        if (index === 0) {
            return <Posts onAddClick={(e) => onPostAddClick(e)} totalPostsLength={posts.length}
                onPush={onPushPosts} clearList={clearList} />
        }

        if (index === 1) {
            return <Twitter />
        }

        if (index === 2) {
            return <PostReview />
        }

        if (index === 3) {
            return <TweetReview />
        }
    }

    return <>
        <div className="home-root">
            <AppBar signOut={signOut} />
            <div className="home-tabs">
                <Tabs value={tabValue} onChange={handleChange} aria-label="Admin tabs">
                    <Tab label="Posts" />
                    <Tab label="Twitter" />
                    <Tab label="Review Posts" />
                    <Tab label="Review Tweets" />
                </Tabs>

                {
                    getContentLayout(tabValue)
                }
            </div>

            <Dialog open={showLoading} >
                <div className="loader-main">
                    <CircularProgress />
                </div>
            </Dialog>

        </div>
    </>
}


export default Home