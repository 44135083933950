module.exports = [

    {
        value: "ARTICLE",
        label: "Article"
    },
    {
        value: "JOB_POST",
        label: "Job Post"
    },
    {
        value: "LIBRARY",
        label: "Library"
    },
    {
        value: "VIDEO_PODCAST",
        label: "Video Or Podcast"
    },
    {
        value: "DESIGN",
        label: "Design"
    }
]