import React, { useState } from "react";
import { Dialog, useTheme } from "@mui/material";
import types from "../../utils/types";
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import useMediaQuery from '@mui/material/useMediaQuery';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import approval_status from "../../utils/approval_status";
import utils from "../../utils/utils";


const PostEdit = ({ item, open, onDialogClose, onSaveClick }) => {

    const [title, setTitle] = useState(item.title)
    const [description, setDescription] = useState(item.description)
    const [approval, setApproval] = useState(item.approvalStatus)
    const [image, setImage] = useState(getImage(item))
    const [type, setType] = useState(item.type)
    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'))

    function onValueChange(type, e) {

        if (type === "title") {
            setTitle(e.target.value.toString().trim())
        }

        if (type === "description") {
            setDescription(e.target.value.toString().trim())
        }
        if (type === "image") {
            setImage(e.target.value.toString().trim())
        }
    }

    const handleChange = (event) => {
        setType(event.target.value);
    };

    const handleApprovalChange = (event) => {
        setApproval(event.target.value)
    }

    function onSave() {
        let newObject = getOutObject()
        if (JSON.stringify(item) !== JSON.stringify(newObject)) {
            onSaveClick(newObject)
        }

        onDialogClose()
    }

    function onClose() {
        onDialogClose()
    }

    function getImage(item) {
        try {
            return item.metas.image
        } catch (e) {
            return null
        }
    }

    function getOutObject() {
        let newItem = JSON.parse(JSON.stringify(item))
        newItem.title = title
        newItem.description = description
        newItem.type = type
        if (!newItem.metas) {
            newItem.metas = {}
        }
        if (utils.isEmpty(image)) {
            newItem.metas.image = null
        } else {
            newItem.metas.image = image
        }
        newItem.approvalStatus = approval
        return newItem
    }

    return <Dialog
        fullScreen={fullScreen}
        open={open}

    >
        <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
                position: 'absolute',
                right: 8,
                top: 24,
                color: (theme) => theme.palette.grey[500],
            }}
        >
            <CloseIcon />
        </IconButton>

        <div className="post-edit-fields">
            <div className="post-edit-title">Edit Post</div>
            <TextField label="Title" variant="outlined" sx={{ maxWidth: '450px', paddingBottom: '16px', width: "100%" }}
                value={title} onChange={(e) => onValueChange('title', e)} autoComplete='off' />
            <br />
            <TextField label="Description" multiline rows={3} variant="outlined" sx={{ maxWidth: '450px', paddingBottom: '16px', width: "100%" }}
                value={description} onChange={(e) => onValueChange('description', e)} autoComplete='off' />
            <br />
            <TextField label="Image" variant="outlined" sx={{ maxWidth: '450px', paddingBottom: '16px', width: "100%" }}
                value={image} onChange={(e) => onValueChange('image', e)} autoComplete='off' />
            <br />
            <TextField
                id="outlined-select-currency"
                select
                label="Select type"
                value={type}
                onChange={handleChange}
                sx={{ maxWidth: '450px', paddingBottom: '16px', width: "100%" }}
                autoComplete='off'
            >
                {types.map((option) => (
                    <MenuItem key={option.value} value={option.value} selected={type === option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </TextField>
            <br />
            <TextField
                id="outlined-select-currency"
                select
                label="Approval Status"
                value={approval}
                onChange={handleApprovalChange}
                sx={{ maxWidth: '450px', paddingBottom: '16px', width: "100%" }}
                autoComplete='off'
            >
                {approval_status.map((option) => (
                    <MenuItem key={option} value={option} selected={approval === option}>
                        {option}
                    </MenuItem>
                ))}
            </TextField>
            <br></br>
            <Button variant="contained" sx={{ maxWidth: '450px', width: "100%" }} onClick={() => onSave()}>Save</Button>
            <br></br>
            <Button variant="outlined" sx={{ maxWidth: '450px', width: "100%", marginTop: "8px" }} onClick={() => onClose()}>Cancel</Button>
        </div>
    </Dialog>

}

export default PostEdit