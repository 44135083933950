import React, { useEffect, useState } from "react";
import CircularProgress from '@mui/material/CircularProgress';
import actions from "../../actions/actions";
import Snackbar from '@mui/material/Snackbar';
import Button from '@mui/material/Button';

const TweetReview = (props) => {

    const [nextPage, setNextPage] = useState(1)
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [showSnack, setShowSnack] = useState(false)
    const [snackMessage, setSnackMessage] = useState('')
    const [updateData, setUpdateData] = useState({})

    useEffect(async () => {
        await loadPages()
    }, [])

    async function loadPages() {
        setLoading(true)
        let response = await actions.getTweetsPaginated(nextPage)
        if (response.data) {
            setNextPage(response.data.nextPage)
            setData(data => [...data, ...response.data.docs]);
        }
        setLoading(false)
    }

    async function onNewItemSave(item) {
        try {
            let response = await actions.updatePost(item)
            if (response.data) {
                setSnackMessage('Updated successfully')
            } else {
                setSnackMessage('Failed to update. Do it again.')
            }
        } catch (e) {
            setSnackMessage('Server error. Do it again.')
        }
        setShowSnack(true)
    }

    async function onSave() {
        try {
            let array = Object.values(updateData)
            if (array.length > 0) {
                let response = await actions.updateTweets(array)
                if (response.data) {
                    setSnackMessage('Updated successfully')
                } else {
                    setSnackMessage('Failed to update. Do it again.')
                }
            } else {
                setSnackMessage('No updates to modify')

            }
        } catch (e) {
            setSnackMessage('Server error. Do it again.')
        }
        setShowSnack(true)
    }

    function onUpdate(current) {

        const newList = data.map(item => {
            if (item._id === current._id) {
                if (item.approvalStatus === "MANUAL_FETCH") {
                    item.approvalStatus = "REJECTED"
                } else {
                    item.approvalStatus = "MANUAL_FETCH"
                }
                var newUpdateData = updateData
                newUpdateData[item._id] = item
                setUpdateData(newUpdateData)
            }
            return item
        })
        setData(newList)

    }

    function getButtonText(status) {
        if (status === "MANUAL_FETCH") {
            return "Reject"
        } else {
            return "Approve"
        }
    }

    function getItemClassName(status) {
        if (status === "MANUAL_FETCH") {
            return "tweet-item"
        } else {
            return "tweet-item-rejected"
        }
    }
    return <>
        <div className="tweets-review-main">

            {
                (data !== null && data.length > 0) ? data.map((item) => {
                    return <div key={item._id} className={getItemClassName(item.approvalStatus)} >

                        <div className="tweet-title">
                            {item.tweets[0].text}
                        </div>
                        <div className="tweet-user">
                            {item.mainLink}
                        </div>
                        <div className="tweet-button" onClick={() => onUpdate(item)}>
                            {getButtonText(item.approvalStatus)}
                        </div>
                    </div>
                }) : <></>
            }

            {
                (nextPage !== null && !loading) ?
                    <div className="tweets-loadmore" onClick={() => loadPages()}>
                        Load More
                    </div> : <></>
            }
            {
                loading ? <div className="tweets-loader">
                    <CircularProgress />
                </div> : <></>
            }

            <Snackbar
                open={showSnack}
                autoHideDuration={1500}
                onClose={() => { setShowSnack(false) }}
                message={snackMessage}
            />
        </div>
        <div className="tweets-save-all">
            <Button variant="contained"
                onClick={onSave} className="tweet-save-button">Update</Button>
        </div>
    </>
}

export default TweetReview