import React, { useEffect, useState } from "react";
import './login.css'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import * as firebase from './../../services/firebase'
import { useNavigate } from "react-router-dom";


const Login = () => {

    const [username, setUserName] = useState("")
    const [password, setPassword] = useState("")
    let navigate = useNavigate();

    function onLogin() {
        firebase.login(username, password)
            .then(data => {
                launchHome()
            }).catch(e => {
                console.log(e)
            })
    }

    useEffect(() => {
        firebase.auth.onAuthStateChanged((user) => {
            if (user) {
                launchHome()
            }
        })
    })

    function launchHome() {
        navigate("/home", { replace: true });
    }

    return (
        <div className="login-root">
            <div className="login-card">
                <div className="login-title">NullHub Admin v1.0</div>

                <div className="login-username">
                    <TextField id="outlined-basic" label="Username" variant="outlined"
                        onChange={e => setUserName(e.target.value)} />
                </div>

                <div className="login-password">
                    <TextField
                        id="standard-password-input"
                        label="Password"
                        type="password"
                        autoComplete="current-password"
                        variant="outlined"
                        onChange={e => setPassword(e.target.value)}
                    />
                </div>

                <Button variant="contained" className="login-button" onClick={onLogin}>Login</Button>
            </div>
        </div>
    )
}

export default Login