import { initializeApp } from 'firebase/app';
import { getDatabase } from "firebase/database";
import { getAuth, signInWithEmailAndPassword, browserLocalPersistence, setPersistence, signOut } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyD_F9DPCf11qBvnKvDcPz3V1jqLeHClBa4",
    authDomain: "nullhub-e7ec1.firebaseapp.com",
    databaseURL: "https://nullhub-e7ec1-default-rtdb.firebaseio.com",
    projectId: "nullhub-e7ec1",
    storageBucket: "nullhub-e7ec1.appspot.com",
    messagingSenderId: "946614514937",
    appId: "1:946614514937:web:0cba55a922419bdaf85e28",
    measurementId: "G-ZDQLMDTNXV"
};


initializeApp(firebaseConfig);
const database = getDatabase()
export const auth = getAuth()


// export const getTableItems = (observer) => {
//     return db.collection("data").doc("locust").onSnapshot(observer);
// };

//export const auth = firebaseAuth

export function login(userId, password) {

    return new Promise((resolve, reject) => {
        if (isEmpty(userId) || isEmpty(password)) {
            reject(Error('Empty values'))
        } else {
            setPersistence(auth, browserLocalPersistence).then(() => {
                return signInWithEmailAndPassword(auth, userId, password)
            }).then((userCredential) => {
                const user = userCredential.user;
                resolve(user)

            }).catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                reject(Error('Unknown user or incorrect credentials'))
            });
        }

    })

}

export const performSignOut = signOut

export function getUser() {
    const user = auth.currentUser;
    return user
}

function isEmpty(str) {
    return (!str || str.length === 0);
}

