import axios from 'axios'
import * as firebase from './../services/firebase'
import { publish } from './eventbus';
import { isDebug } from '../utils/utils';


function getHost() {
    if (isDebug()) {
        return "http://localhost:8000/api/v1/"
    } else {
        return "https://onyx.nullhub.in/api/v1/"
    }
}

const URL = getHost()

axios.interceptors.request.use(function (config) {
    const token = localStorage.getItem('token')
    config.headers['g-auth'] = token;
    config.headers['platform'] = "web-am"
    return config;
});

axios.interceptors.response.use(function (response) {
    if (response.status === 403 || response.status === 401) {
        publish('logout', "Unauthorized")
    }
    return response;
}, function (error) {
    return Promise.reject(error);
});


async function postPosts(data, token, uid) {
    let result = await axios.post(URL + "posts/new-post", data, {
        headers: {
            fAuth: token,
            uid: uid
        }
    })
    return result.data
}

async function getLinkDetails(link) {

    try {
        let token = await firebase.getUser().getIdToken()
        let uid = await firebase.getUser().uid

        let result = await axios.post(URL + "utils/link-preview", {
            link: link
        }, {
            headers: {
                fAuth: token,
                uid: uid
            }
        })
        return result.data
    } catch (e) {
        return null
    }
}

async function getPostsPaginated(page) {
    let result = await axios.get(URL + `posts?page=${page}`)
    return result
}

async function updatePost(post) {
    let out = []
    out.push(post)
    let result = await axios.post(URL + `posts`, out)
    return result
}

async function postTweets(urls) {
    let result = await axios.post(URL + `social/tweet`, urls)
    return result.data
}

async function getTweetsPaginated(page) {
    let result = await axios.get(URL + `social/tweets?page=${page}`)
    return result
}

async function updateTweets(out) {
    let result = await axios.post(URL + `social/tweets`, out)
    return result
}


export default {
    postPosts: postPosts,
    getLinkDetails: getLinkDetails,
    getPostsPaginated: getPostsPaginated,
    updatePost: updatePost,
    postTweets: postTweets,
    getTweetsPaginated: getTweetsPaginated,
    updateTweets: updateTweets
}