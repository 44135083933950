import React, { useState } from "react";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import types from "../../utils/types";
import utils from "../../utils/utils";
import CircularProgress from '@mui/material/CircularProgress';
import actions from "../../actions/actions";


const Posts = ({ onAddClick, totalPostsLength, onPush, clearList }) => {

    const [type, setType] = React.useState('');
    const [link, setLink] = React.useState('')
    const [description, setDescription] = React.useState('')
    const [title, setTitle] = React.useState('')
    const [image, setImage] = React.useState('')
    const [linkFetchLoading, setLinkFetchLoading] = React.useState(false)


    const handleChange = (event) => {
        setType(event.target.value);
    };

    function onValueChange(type, e) {

        if (type === "title") {
            setTitle(e.target.value.toString().trim())
        }
        if (type === "link") {
            setLink(e.target.value.toString().trim())
            setLinkFetchLoading(false)
        }
        if (type === "description") {
            setDescription(e.target.value.toString().trim())
        }
        if (type === "image") {
            setImage(e.target.value.toString().trim())
        }
    }

    function isEligible() {
        return (!utils.isEmpty(title) && !utils.isEmpty(link) && !utils.isEmpty(type))
    }

    function onAddAndProceed() {

        if (isEligible()) {
            onAddClick(getOutObj())
            clearAll()
        }
    }

    function getOutObj() {
        let obj = {
            title: title,
            link: link,
            description: description,
            type: type
        }

        if (!utils.isEmpty(image)) {
            obj.metas = {
                image: image
            }
        }
        return obj
    }

    function clearAll() {
        setType('')
        setLink('')
        setTitle('')
        setImage('')
        setDescription('')
    }

    function superClear() {
        clearAll()
        clearList()
    }

    function onPasteDetected(e) {
        setTimeout(async () => {
            let value = e.target.value
            if (value) {
                setLinkFetchLoading(true)
                let data = await actions.getLinkDetails(value)
                if (data) {
                    if (data.title) {
                        setTitle(data.title)
                    }
                    if (data.description) {
                        setDescription(data.description)
                    }
                    if (data.image) {
                        setImage(data.image)
                    }
                }
                setLinkFetchLoading(false)
            } else {
                setLinkFetchLoading(false)
            }
        }, 600)
    }


    return <div className="posts-root">

        <div className="posts-main">
            <TextField label="Link" variant="outlined" sx={{ maxWidth: '450px', marginTop: "16px", paddingBottom: '16px', width: "100%" }}
                value={link} onChange={(e) => onValueChange('link', e)} autoComplete='off'
                onPaste={(e) => onPasteDetected(e)} />
            <br />
            {linkFetchLoading ? <div>
                <CircularProgress />
                <br />
            </div> : <></>}
            <TextField disabled={linkFetchLoading} label="Title" variant="outlined" sx={{ maxWidth: '450px', paddingBottom: '16px', width: "100%" }}
                value={title} onChange={(e) => onValueChange('title', e)} autoComplete='off' />
            <br />
            <TextField disabled={linkFetchLoading} label="Description" multiline rows={3} variant="outlined" sx={{ maxWidth: '450px', paddingBottom: '16px', width: "100%" }}
                value={description} onChange={(e) => onValueChange('description', e)} autoComplete='off' />
            <br />
            <TextField disabled={linkFetchLoading} label="Image" variant="outlined" sx={{ maxWidth: '450px', paddingBottom: '16px', width: "100%" }}
                value={image} onChange={(e) => onValueChange('image', e)} autoComplete='off' />
            <br />
            <TextField
                id="outlined-select-currency"
                select
                label="Select type"
                value={type}
                onChange={handleChange}
                sx={{ maxWidth: '450px', paddingBottom: '16px', width: "100%" }}
                autoComplete='off'
            >
                {types.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </TextField>

            <br /><br />
            <div className="posts-save-all">
                <Button variant="text" onClick={clearAll} sx={{ marginRight: "16px" }}>Clear</Button>
                <Button variant="contained"
                    onClick={onAddAndProceed}>Add and Proceed</Button>


            </div>

            <br />
            <div className="posts-save-all">
                <Button variant="text" onClick={superClear} sx={{ marginRight: "16px" }} >Clear All</Button>
                <Button variant="contained" disabled={totalPostsLength <= 0}
                    onClick={onPush}>Save All ({totalPostsLength})</Button>

            </div>
        </div>
    </div>
}

export default Posts