

function isEmpty(str) {
    return (!str || str.length === 0);
}

function isDebug() {
    return (!process.env.NODE_ENV || process.env.NODE_ENV === 'development')
}
module.exports = {
    isEmpty: isEmpty,
    isDebug: isDebug
}