import React, { useEffect, useState } from "react";
import './splash.css'
import * as firebase from './../../services/firebase'
import { useNavigate } from "react-router-dom";
import logo from './../../assets/logo.png'

const Splash = () => {

    let navigate = useNavigate();


    useEffect(() => {
        firebase.auth.onAuthStateChanged((user) => {
            if (user) {
                launchHome()
            } else {
                launchLogin()
            }
        })
    })

    function launchHome() {
        navigate("/home", { replace: true });
    }

    function launchLogin() {
        navigate("/login", { replace: true });
    }

    return (
        <div className="splash-root">
            <img src={logo} width="90" height="90" />
        </div>
    )
}


export default Splash