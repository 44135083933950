import { AppBar } from "@mui/material";
import React from "react";
import './appbar.css'
import logo from './../../assets/logo.png'
import Button from '@mui/material/Button';

const Appbar = ({ signOut }) => {

    return <div className="appbar-main">
        <div className="icon">
            <img src={logo} width="36" height="36" />
            <p>Admin</p>
        </div>
        <Button variant="text" onClick={signOut}>Sign Out</Button>
    </div>
}

export default Appbar