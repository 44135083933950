import React from "react";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar';
import actions from "../../actions/actions";

const Twitter = (props) => {

    const [urls, setUrls] = React.useState([""])
    const [showSnack, setShowSnack] = React.useState(false)
    const [snackMessage, setSnackMessage] = React.useState('')
    const [saving, setSaving] = React.useState(false)

    function onAddMoreFieldClick() {
        if (isExistingUrlsValid()) {
            setUrls([...urls, ""])
        } else {
            setSnackMessage('Urls are not in proper structure')
            setShowSnack(true)
        }
    }

    function isExistingUrlsValid() {
        let isValid = true
        for (let i = 0; i < urls.length; i++) {
            isValid = isValidHttpUrl(urls[i])
            if (!isValid) {
                break;
            }
        }
        return isValid
    }

    function isValidHttpUrl(string) {
        let url;

        try {
            url = new URL(string);
        } catch (_) {
            return false;
        }

        return (url.protocol === "http:" || url.protocol === "https:")
            && (url.host.includes('twitter'));
    }

    function onValueEntered(value, index) {
        const newUrls = [...urls]
        newUrls[index] = value.toString()
        setUrls(newUrls)
    }

    async function onSaveClick() {
        try {
            if (isExistingUrlsValid()) {
                setSaving(true)
                let data = await actions.postTweets(urls)
                if (data.status) {
                    setSnackMessage('Operation success')
                    setShowSnack(true)
                    setUrls([""])
                }

            } else {
                setSnackMessage('Urls are not in proper structure')
                setShowSnack(true)
            }
        } catch (e) {
            setSnackMessage('Operation failed. Please try again')
            setShowSnack(true)
        }
        setSaving(false)
    }


    return <div className="twitter-main">

        {

            urls.map((url, index) => {
                return <div key={index}>
                    <TextField label="Add tweet link" variant="outlined" sx={{ maxWidth: '450px', marginTop: "16px", paddingBottom: '16px', width: "100%" }}
                        value={url} autoComplete='off' onChange={(e) => onValueEntered(e.target.value, index)} />
                    <br />
                </div>
            })
        }

        <div className="twitter-actions">
            <Button variant="outlined" sx={{ marginRight: "16px" }} onClick={onAddMoreFieldClick} >Add More</Button>
            {
                (!saving) ?
                    <Button variant="contained" sx={{ marginRight: "16px" }} onClick={onSaveClick}>Save</Button> :
                    <CircularProgress />
            }

        </div>
        <Snackbar
            open={showSnack}
            autoHideDuration={1500}
            onClose={() => { setShowSnack(false) }}
            message={snackMessage}
        />
    </div>

}

export default Twitter